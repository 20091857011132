import * as React from 'react';
import Seo from '../components/seo';
import Layout from '../components/layout';
import Slideshow from '../components/Slideshow/Slideshow';
import FeatureSectionRight from '../components/FeatureSectionRight/FeatureSectionRight';

const GreetingPage = ({ data, location }) => {
  return (
    <div>
      <Layout>
        <Slideshow
          title="GREETING"
          subtitle="代表挨拶"
          images={[
            { id: 2, src: '/nagaoka2023/image/top2.png', alt: 'Image 2' },
            { id: 3, src: '/nagaoka2024/t101.jpg', alt: 'Image 3' },
            { id: 1, src: '/nagaoka2024/t104.jpg', alt: 'Image 1' },
          ]}
        />
        <FeatureSectionRight
          imageSrc="/nagaoka2023/image/greeting1.png"
          imageAlt="Image 1"
          title="代表挨拶"
          founderName={`代表 石田 博和`}
          description={`私、石田博和は大学在学時、都内のイタリアンレストランでのアルバイトを きっかけに、飲食の面白さを知りたいと心より思いました。

          大学卒業後は別業界で就職したのち、 フランチャイズチェーンを展開する飲食企業に転職。 料理の技術だけでなく、店舗運営のノウハウを勉強するきっかけとなったのです。
          
          そして31歳の時、北九州小倉の地に自身の焼肉店を開店し、 2019年より「肉のながおか」として移転オープンしました。
          
          「高級感溢れる大人の隠れ家」として素材の良さ・自慢の調理方法を活かした 料理を提供し続けられるよう、今後とも精進してまいります。`}
        />
      </Layout>
    </div>
  );
};

export default GreetingPage;

export const Head = () => (
  <Seo
    title="代表挨拶"
    description="私、石田博和は大学在学時、都内のイタリアンレストランでのアルバイトを きっかけに、飲食の面白さを知りたいと心より思いました。"
  />
);
